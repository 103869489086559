import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'presDate'
})
export class PresDatePipe implements PipeTransform {

  transform(value: any, args?: any): any {
    switch(value){
      case '2023-12-11':return 'Lunes';
      case '2023-12-12':return 'Martes';
      case '2023-12-13':return 'Miércoles';
      case '2023-12-14':return 'Jueves';
      case '2023-12-15':return 'Viernes';
      default:return 'Invalid Date';
    }
    return null;
  }

}
