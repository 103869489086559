import { Component, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute, RouterOutlet } from '@angular/router';
import { trigger, transition, group, query, style, animate } from '@angular/animations';
import { FormGroup, FormControl } from '@angular/forms';
import { ApiService } from '../api.service';
import { ModalTrabajoComponent } from '../modal-trabajo/modal-trabajo.component';
import { MatSelect } from '@angular/material/select';
import { MatDialog } from '@angular/material/dialog';
import { MatStepper } from '@angular/material/stepper';
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css'],
  animations: [
    trigger('routeAnimation', [
      transition(':increment', [
        style({ height: '!' }),
        query(':enter', style({ transform: 'translateX(100%)' })),
        query(':enter, :leave', style({ position: 'absolute', top: 0, left: 0, right: 0, opacity: 0 })),
        group([
          query(':leave', [
            animate('0.3s cubic-bezier(.35,0,.25,1)', style({ transform: 'translateX(-100%)', opacity: 0 }))
          ]),
          // and now reveal the enter
          query(':enter', animate('0.25s ease-out', style({ transform: 'translateX(3%)', opacity: 1 }))),
        ])
      ]),
      transition(':decrement', [
        style({ height: '!' }),
        query(':enter', style({ transform: 'translateX(-100%)' })),
        query(':enter, :leave', style({ position: 'absolute', top: 0, left: 0, right: 0, opacity: 0 })),
        // animate the leave page away
        group([
          query(':leave', [
            animate('0.3s cubic-bezier(.35,0,.25,1)', style({ transform: 'translateX(100%)', opacity: 0 })),
          ]),
          // and now reveal the enter
          query(':enter', animate('0.25s cubic-bezier(.35,0,.25,1)', style({ transform: 'translateX(3%)', opacity: 1 }))),
        ]),
      ]),
    ])
  ]
})
export class HomeComponent implements OnInit {

  lastDepth: number;
  searchForm: FormGroup;
  abstracts: any[] = [];
  fixedAbstracts: any[] = [];
  consultingServer: boolean;
  consulted: boolean;
  showToTime: any;
  @ViewChild('stepper') stepperH: MatStepper;
  @ViewChild('end_select') end_select: MatSelect;
  showFromTime: any;
  toDate: any = [
    '08:45:00',
    '09:00:00',
    '09:15:00',
    '09:30:00',
    '09:45:00',
    '10:00:00',
    '10:15:00',
    '10:30:00',
    '10:45:00',
    '11:00:00',
    '11:15:00',
    '11:30:00',
    '11:45:00',
    '12:00:00',
    '12:15:00',
    '12:30:00',
    '12:45:00',
    '13:00:00',
    '13:15:00',
    '13:30:00',
    '13:45:00',
    '14:00:00',
    '14:15:00',
    '15:00:00',
    '15:15:00',
    '15:30:00',
    '15:45:00',
    '16:00:00',
    '16:15:00',
    '16:30:00',
    '16:45:00',
    '17:00:00',
    '17:15:00',
    '17:30:00',
    '17:45:00',
    '18:00:00',
    '18:15:00',
    '18:30:00',
    '18:45:00',
    '19:00:00',
    '19:15:00',
    '19:30:00',
    '21:00:00'
  ];
  fromDate: any = [
    '08:30:00',
    '08:45:00',
    '09:00:00',
    '09:15:00',
    '09:30:00',
    '09:45:00',
    '10:00:00',
    '10:15:00',
    '10:30:00',
    '10:45:00',
    '11:00:00',
    '11:15:00',
    '11:30:00',
    '11:45:00',
    '12:00:00',
    '12:15:00',
    '12:30:00',
    '12:45:00',
    '13:00:00',
    '13:15:00',
    '13:30:00',
    '13:45:00',
    '14:00:00',
    '14:15:00',
    '15:00:00',
    '15:15:00',
    '15:30:00',
    '15:45:00',
    '16:00:00',
    '16:15:00',
    '16:30:00',
    '16:45:00',
    '17:00:00',
    '17:15:00',
    '17:30:00',
    '17:45:00',
    '18:00:00',
    '18:15:00',
    '18:30:00',
    '18:45:00',
    '19:00:00',
    '19:15:00',
  ];

  constructor(private router: Router, private route: ActivatedRoute, private api: ApiService, private dialog: MatDialog) {
    this.initForm();
  }

  ngOnInit() {
    this.showFromTime = this.fromDate;
    this.showToTime = this.toDate;
    if (this.router.url === '/') {
      this.router.navigate(['programa-general']);
    }
  }

  search() {
    if (!this.consultingServer) {
      if (this.stepperH.selectedIndex > 0)
        this.stepperH.selectedIndex = 0;

      this.fixedAbstracts = [];
      this.abstracts = [];
      this.consultingServer = true;

      let data = {
        title: this.searchForm.controls['title'].value.replace(/[\u00A0-\u9999<>\&]/gim, function (i) {
          return '&#' + i.charCodeAt(0) + ';';
        }),
        title_upper: this.searchForm.controls['title'].value.toUpperCase().replace(/[\u00A0-\u9999<>\&]/gim, function (i) {
          return '&#' + i.charCodeAt(0) + ';';
        }),
        // start_time: this.searchForm.controls['start_time'].value,
        // end_time: this.searchForm.controls['end_time'].value,
        date: this.searchForm.controls['date'].value,
        room: '',
        event_cod: 'xiictdc',
        title_decoded: this.searchForm.controls['title'].value,
        vals: []
      }

      let word: string = '%';
      let words: string[] = [];
      for (let c in data.title_decoded) {
        if (data.title_decoded[parseInt(c)] !== ' ')
          word += data.title_decoded[c];

        if (data.title_decoded[parseInt(c) + 1] === undefined || data.title_decoded[parseInt(c) + 1] === ' ') {
          word += '%';
          words.push(word);
          word = '%';
        }
      }

      for (let c in data.title) {
        if (data.title[parseInt(c)] !== ' ')
          word += data.title[c];

        if (data.title[parseInt(c) + 1] === undefined || data.title[parseInt(c) + 1] === ' ') {
          word += '%';
          words.push(word);
          word = '%';
        }
      }

      data.vals = words;

      this.api.search(data).then(res => {
        this.consulted = true;
        this.consultingServer = false;
        this.abstracts = res;
        this.fixArray();
      });
    }
  }

  goToStep(number: number) {
    this.stepperH.selectedIndex = number;
  }

  showModal(abstract: any) {
    if (abstract.id_tipo === 348 || this.api.notShowable(abstract.cod_abstract)) {
      return;
    }
    let dialogRef;
    if (window.screen.width > 1366) {
      dialogRef = this.dialog.open(ModalTrabajoComponent, {
        width: '50%',
        height: '65%',
        data: abstract,
        autoFocus: false
      });
    }
    else if (window.screen.width <= 1366 && window.screen.width >= 1024) {
      dialogRef = this.dialog.open(ModalTrabajoComponent, {
        width: '80%',
        height: '85%',
        data: abstract,
        autoFocus: false
      });
    }
    else{
      dialogRef = this.dialog.open(ModalTrabajoComponent, {
        width: '95%',
        height: '95%',
        maxWidth:'95%',
        maxHeight:'95%',
        data: abstract,
        autoFocus: false
      });
    }

  }

  updateToTime(value: any) {
    // (value);
    this.showToTime = [];
    for (let i in this.fromDate) {
      if (this.fromDate[i] === value) {
        this.showToTime = this.fromDate.slice(parseInt(i) + 1, this.fromDate.length);
      }
    }
    setTimeout(() => {
      this.searchForm.controls['end_time'].setValue('19:30:00');
    }, 1000);

  }

  fixArray() {
    let tempArray: any[] = [];
    for (let i = 0; i < this.abstracts.length; i++) {
      tempArray.push(this.abstracts[i]);
      if (tempArray.length >= 20 || this.abstracts[i + 1] === undefined) {
        this.fixedAbstracts.push(tempArray);
        tempArray = [];
      }
    }
  }

  initForm() {
    this.searchForm = new FormGroup({
      title: new FormControl(''),
      date: new FormControl(''),
      start_time: new FormControl('08:30:00'),
      end_time: new FormControl('21:00:00'),
      room: new FormControl('')
    });
  }

  getDepth(outlet: RouterOutlet) {
    if (outlet.activatedRoute.firstChild !== null) {
      if (outlet.activatedRoute.firstChild.data['value'].depth > 2)
        return outlet.activatedRoute.firstChild.data['value'].depth;
    }
    return outlet.activatedRouteData['depth'];
  }

}
