import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'area'
})
export class AreaPipe implements PipeTransform {

  transform(value: any, args?: any): any {
    let newString:string='';
    for(let c of value){
      if(c!==':'){
        newString+=c;
      }
      else{
        break;
      }
    }
    return newString;
  }

}
