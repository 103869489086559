import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { timer } from 'rxjs';
import { trigger, transition, style, animate, keyframes } from '@angular/animations';
import { ApiService } from '../api.service';

@Component({
  selector: 'app-calendario-orales',
  templateUrl: './calendario-orales.component.html',
  styleUrls: ['./calendario-orales.component.css'],
  animations: [
    trigger('room', [
      transition(':increment', [
        animate('0.3s cubic-bezier(.35,0,.25,1)', keyframes([
          style({ transform: 'translatex(-100%)', offset: 0.4 }),
          style({ opacity: 0, offset: 0.45 }),
          style({ transform: 'translateX(100%)', opacity: 0, offset: 0.5 }),
          style({ transform: 'translateX(0)', opacity: 1, offset: 1 }),
        ]))
      ]),
      transition(':decrement', [
        animate('0.3s cubic-bezier(.35,0,.25,1)', keyframes([
          style({ transform: 'translatex(100%)', offset: 0.45 }),
          style({ opacity: 0, offset: 0.45 }),
          style({ transform: 'translateX(-100%)', opacity: 0, offset: 0.5 }),
          style({ transform: 'translateX(0)', opacity: 1, offset: 1 }),
        ]))
      ])
    ])
  ]
})
export class CalendarioOralesComponent implements OnInit {

  response: any;
  session: string;
  room: string;
  backString: string;
  start_time: string;
  end_time: string;
  type: string;
  date: string;
  consultingServer: boolean;
  roomN: number;
  roomAnim: string;
  constructor(private route: ActivatedRoute, private api: ApiService, private router:Router) { }

  ngOnInit() {
    this.route.paramMap.subscribe((param: ParamMap) => {
      this.roomAnim = param.get('room');
      timer(150).subscribe(() => {
        this.roomN = parseInt(param.get('room'));
      })

    });
  }

  nav(route:string){
    this.router.navigate([route]);
  }


  onNavBack(event) {
    this.response = undefined;
  }

  getSes(date: string, room: string, start: string, end: string, area: string, back: string, type: string) {
    this.consultingServer = true;
    this.session = area;
    this.backString = back;
    this.start_time = start;
    this.end_time = end;
    this.type = type;
    this.date = date;
    this.room = room;
    let data = {
      date: date,
      room: room,
      start_time: start,
      end_time: end,
      area: area
    }


    this.api.session(data).then(
      res => {
        this.consultingServer = false;
        this.response = res;
      }
    )
  }

}
