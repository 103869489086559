import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  headers:HttpHeaders = new HttpHeaders({'token':'H4zGWcb85pfRFbcSL5gyYUEW2psHx6d9GKx7XB7Y', 'Content-Type':'application/json'});
  searchApiUrl:string='https://endpoint.4id.cl/login/posters-alam';
  coautorsUrl:string='https://endpoint.4id.cl/login/coautors';
  sessionUrl:string='https://endpoint.4id.cl/login/datetimepres';
  areaUrl:string='https://endpoint.4id.cl/login/areapres';

  public showAbs:string[]=[
    'MRED002',
    'MRED003',
    'MRED005',
    'MRED006',
    'MRED007',
  ]
  constructor(private http:HttpClient) { 

  }

  notShowable(abstract_cod:string){
    return this.showAbs.includes(abstract_cod);
  }



  search(form:any){
    return this.http.post(this.searchApiUrl,form, {headers:this.headers})
    .toPromise()
    .then(response=>response)
    .catch(error=>error);
  }

  coautors(data){
    return this.http.post(this.coautorsUrl,data, {headers:this.headers})
    .toPromise()
    .then(response=>response)
    .catch(error=>error);
  }

  area(data){
    return this.http.post(this.areaUrl,data, {headers:this.headers})
    .toPromise()
    .then(response=>response)
    .catch(error=>error);
  }

  session(data){
    let d = {
      start_time: data['start_time'],
      end_time: data['end_time'],
      date: data['date'],
      room: data['room'],
      event_cod: 'xiictdc',
      area:data['area']
    }

    return this.http.post(this.sessionUrl,d, {headers:this.headers})
    .toPromise()
    .then(response=>response)
    .catch(error=>error);
  }
}
