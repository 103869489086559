import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'room'
})
export class RoomPipe implements PipeTransform {

  transform(value: any, args?: any): any {
    switch(value){
      case 's1': return 'Sala O1';
      case 's2': return 'Sala O2';
      case 's3': return 'Sala O3';
      case 's4': return 'Sala O4';
      case 's5': return 'Sala O5';
      case 'sp1': return 'Sala P1';
      case 'sp2': return 'Sala P2';
      case 'sp3': return 'Sala P3';
      case 'sp4': return 'Sala P4';
      case 'sc1': return 'Sala Plenaria 1';
      case 'sc2': return 'Sala Plenaria 2';
      case 'sc3': return 'Foro UDEC';
    }
    return value;
  }

}
