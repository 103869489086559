import { Directive, ElementRef, Renderer, OnInit, Input } from '@angular/core';

@Directive({
  selector: '[areaColor]'
})
export class AreaColorDirective implements OnInit {

  @Input('area') area: string;
  constructor(private el: ElementRef, private renderer: Renderer) {

  }

  ngOnInit() {
    if (this.area.includes('MAGM') || this.area.includes('magm') ) {
      this.renderer.setElementStyle(this.el.nativeElement, 'color', 'white');
      this.renderer.setElementStyle(this.el.nativeElement, 'background-color', '#ff6633');
    }
    else if (this.area.includes('VOLC') || this.area.includes('volc')) {
      this.renderer.setElementStyle(this.el.nativeElement, 'color', 'white');
      this.renderer.setElementStyle(this.el.nativeElement, 'background-color', '#cc6699');
    }
    else if (this.area.includes('ESEG') || this.area.includes('eseg')) {
      this.renderer.setElementStyle(this.el.nativeElement, 'color', 'white');
      this.renderer.setElementStyle(this.el.nativeElement, 'background-color', '#cc9966');
    }
    else if (this.area.includes('AEGS') || this.area.includes('aegs')) {
      this.renderer.setElementStyle(this.el.nativeElement, 'color', 'white');
      this.renderer.setElementStyle(this.el.nativeElement, 'background-color', '#ff9999');
    }
    else if (this.area.includes('GEOS') || this.area.includes('geos')) {
      this.renderer.setElementStyle(this.el.nativeElement, 'color', 'white');
      this.renderer.setElementStyle(this.el.nativeElement, 'background-color', '#3399cc');
    }
    else if (this.area.includes('GIAM') || this.area.includes('giam')) {
      this.renderer.setElementStyle(this.el.nativeElement, 'color', 'white');
      this.renderer.setElementStyle(this.el.nativeElement, 'background-color', '#cccc00');
    }
    else if (this.area.includes('GMPE') || this.area.includes('gmpe')) {
      this.renderer.setElementStyle(this.el.nativeElement, 'color', 'white');
      this.renderer.setElementStyle(this.el.nativeElement, 'background-color', '#99cc33');
    }
    else if (this.area.includes('ESSP') || this.area.includes('essp')) {
      this.renderer.setElementStyle(this.el.nativeElement, 'color', 'white');
      this.renderer.setElementStyle(this.el.nativeElement, 'background-color', '#91cfb1');
    }
    else if (this.area.includes('PRIN') || this.area.includes('prin')) {
      this.renderer.setElementStyle(this.el.nativeElement, 'color', 'white');
      this.renderer.setElementStyle(this.el.nativeElement, 'background-color', '#6666cc');
    }
    else if (this.area.includes('RENE') || this.area.includes('rene')) {
      this.renderer.setElementStyle(this.el.nativeElement, 'color', 'white');
      this.renderer.setElementStyle(this.el.nativeElement, 'background-color', '#ffcc33');
    }
    else if (this.area.includes('SINT') || this.area.includes('sint')) {
      this.renderer.setElementStyle(this.el.nativeElement, 'color', 'white');
      this.renderer.setElementStyle(this.el.nativeElement, 'background-color', '#a79251');
    }
    
    
  }

}
