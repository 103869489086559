import { Component, OnInit } from '@angular/core';
import {ApiService} from '../api.service';
import { trigger, transition, group, query, style, animate } from '@angular/animations';
@Component({
  selector: 'app-lunes1',
  templateUrl: './lunes1.component.html',
  styleUrls: ['./lunes1.component.css'],
  animations:[
    trigger('show',
    [
      
    ])
  ]
})
export class Lunes1Component implements OnInit {

  response:any;
  session:string;
  room:string;
  backString:string;
  start_time:string;
  end_time:string;
  type:string;
  date:string;
  consultingServer:boolean;
  constructor(private api:ApiService) { 

  }

  ngOnInit() {

  }

  onNavBack(event){
      this.response=undefined;

  }

  getSes(date:string,room:string, start:string,end:string,area:string, back:string, type:string){
    this.consultingServer=true;
    this.session=area; 
    this.backString=back;
    this.start_time=start;
    this.end_time=end;
    this.type=type;
    this.date=date;
    this.room=room;
    let data = {
      date:date,
      room:room,
      start_time:start,
      end_time:end,
      area:area
    }



    this.api.session(data).then(
      res=>{
        this.consultingServer=false;
        this.response=res;
      }
    )
  }

}
