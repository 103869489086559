import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ApiService } from '../api.service';
@Component({
  selector: 'app-modal-trabajo',
  templateUrl: './modal-trabajo.component.html',
  styleUrls: ['./modal-trabajo.component.css']
})
export class ModalTrabajoComponent implements OnInit {

  consultingServer:boolean;
  coautors: any = [];
  constructor(@Inject(MAT_DIALOG_DATA) public abstract: any, public dialogRef: MatDialogRef<ModalTrabajoComponent>, private api: ApiService) { }

  ngOnInit() {
    this.consultingServer=true;
    const data = {
      abstract_cod: this.abstract['cod_abstract'],
      event_cod: 'xiictdc'
    }

    this.api.coautors(data).then(res => {
      this.coautors = res;
      this.consultingServer=false;
    });
  }

  getFils(coautor: any) {

    let filOrder: any[] = [];

    for (let x in this.coautors.fils) {
      for (let fil of coautor) {
        if (fil.universidad === this.coautors.fils[parseInt(x)].universidad && fil.departamento === this.coautors.fils[parseInt(x)].departamento && fil.facultad === this.coautors.fils[parseInt(x)].facultad) {
            filOrder.push(parseInt(x) + 1);
        }
      }
    }
    return filOrder;
  }

}
