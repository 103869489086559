import { Component, OnInit, Input, Output } from '@angular/core';
import { EventEmitter } from 'events';

@Component({
  selector: 'time-block',
  templateUrl: './time-block.component.html',
  styleUrls: ['./time-block.component.css']
})
export class TimeBlockComponent implements OnInit {

  @Input('link') link:string;
  @Input('title') title:string;
  @Input('rowspan') rowspan:number;
  @Output() navigate = new EventEmitter(); 
  constructor() { }

  ngOnInit() {
  }

  nav(){
    this.navigate.emit(this.link);
  }

}
