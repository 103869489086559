import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ModalTrabajoComponent } from '../modal-trabajo/modal-trabajo.component';
import { Router, ParamMap, RouterEvent } from '@angular/router';
import { timer } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { ApiService } from '../api.service';
@Component({
  selector: 'lista-trabajos',
  templateUrl: './lista-trabajos.component.html',
  styleUrls: ['./lista-trabajos.component.css']
})
export class ListaTrabajosComponent implements OnInit {

  @Input('abstracts') abstracts: any = [];
  @Input('area') area: string;
  @Input('room') room: string;
  @Input('start_time') start_time: string;
  @Input('end_time') end_time: string;
  @Input('type') type: string;
  @Input('date') date: string;
  @Output() back: EventEmitter<any> = new EventEmitter<any>();
  currentRoute:string;
  paramet:boolean;
  consultingServer: boolean;
  constructor(private dialog: MatDialog, private router: Router, private route: ActivatedRoute, private api: ApiService) { 
    this.router.events.subscribe((event:RouterEvent)=>{
      if(event.url!==this.currentRoute){
        this.navBack();
      }
    });

  }

  ngOnInit() {
    this.currentRoute=this.router.url;
    this.route.paramMap.subscribe((param: ParamMap) => {
      if (this.router.url.includes('trabajos')) {
        this.paramet=true;
        this.consultingServer = true;
        this.area = param.get('area');
        let data = {
          event_cod: "xiictdc",
          area: this.area
        }
        this.api.area(data).then(res => {
          this.abstracts = res;
          this.consultingServer = false;
        });
      }
    });
  }

  navBack() {
    this.back.emit('back');
  }

  nav(route:string){
    this.router.navigate([route])
  }

  changeRoom(event) {
    this.back.emit('back');
    timer(1).subscribe(() => {
      this.router.navigate(['areas/orales/' + event.value]);
    });

  }


  showModal(abstract: any) {
    if (abstract.id_tipo === 348 || this.api.notShowable(abstract.cod_abstract)) {
      return;
    }
    let dialogRef;
    if (window.screen.width > 1366) {
      dialogRef = this.dialog.open(ModalTrabajoComponent, {
        width: '50%',
        height: '65%',
        data: abstract,
        autoFocus: false
      });
    }
    else if (window.screen.width <= 1366 && window.screen.width >= 1024) {
      dialogRef = this.dialog.open(ModalTrabajoComponent, {
        width: '70%',
        height: '85%',
        data: abstract,
        autoFocus: false
      });
    }
    else {
      dialogRef = this.dialog.open(ModalTrabajoComponent, {
        width: '95%',
        height: '95%',
        maxWidth:'95%',
        maxHeight:'95%',
        data: abstract,
        autoFocus: false
      });
    }
  }

}
