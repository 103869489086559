import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import * as moment from 'moment';
@Component({
  selector: 'app-areas-table',
  templateUrl: './areas-table.component.html',
  styleUrls: ['./areas-table.component.css']
})
export class AreasTableComponent implements OnInit {
  dia: any;

  constructor(private route: ActivatedRoute) {

  }

  ngOnInit() {
    moment.locale('es');
    let d: any = moment(this.route.snapshot.paramMap.get('inicio'));
    this.dia = moment(d._i).format('dddd');
    //  (this.route.snapshot.paramMap.get('inicio'));
    //  (this.route.snapshot.paramMap.get('fin'));
  }

}
