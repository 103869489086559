import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import {MatMenuModule} from '@angular/material/menu';
import {MatButtonModule} from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatInputModule} from '@angular/material/input';
import {MatSelectModule} from '@angular/material/select';
import{MatStepperModule} from '@angular/material/stepper';
import {MatDialogModule} from '@angular/material/dialog';
import {HttpClientModule} from  '@angular/common/http';
import{FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatExpansionModule} from '@angular/material/expansion';
import { AppComponent } from './app.component';
import { HeaderComponent } from './header/header.component';
import { HomeComponent } from './home/home.component';
import { CalendarioTableComponent } from './calendario-table/calendario-table.component';
import { AreasTableComponent } from './areas-table/areas-table.component';
import { ListaTrabajosComponent } from './lista-trabajos/lista-trabajos.component';
import { ModalTrabajoComponent } from './modal-trabajo/modal-trabajo.component';
import { CalendarioPostersComponent } from './calendario-posters/calendario-posters.component';
import { CalendarioPlenariasComponent } from './calendario-plenarias/calendario-plenarias.component';
import { CalendarioPlenarias2Component } from './calendario-plenarias2/calendario-plenarias2.component';
import { CalendarioOralesComponent } from './calendario-orales/calendario-orales.component';
import { CalendarioDetalleComponent } from './calendario-detalle/calendario-detalle.component';
import { TimeBlockComponent } from './time-block/time-block.component';
import { TimeBlockDirective } from './time-block.directive';
import { Lunes1Component } from './lunes1/lunes1.component';
import { Lunes2Component } from './lunes2/lunes2.component';
import { Lunes3Component } from './lunes3/lunes3.component';
import { Martes1Component } from './martes1/martes1.component';
import { Martes2Component } from './martes2/martes2.component';
import { Martes3Component } from './martes3/martes3.component';
import { Miercoles1Component } from './miercoles1/miercoles1.component';
import { Miercoles2Component } from './miercoles2/miercoles2.component';
import { Miercoles3Component } from './miercoles3/miercoles3.component';
import { Jueves1Component } from './jueves1/jueves1.component';
import { Jueves3Component } from './jueves3/jueves3.component';
import { Viernes1Component } from './viernes1/viernes1.component';
import { Viernes3Component } from './viernes3/viernes3.component';
import { Jueves4Component } from './jueves4/jueves4.component';
import{ApiService} from './api.service';
import { TimePipe } from './time.pipe';
import { RoomPipe } from './room.pipe';
import { PresDatePipe } from './pres-date.pipe';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import { AreaPipe } from './area.pipe';
import { AreaColorDirective } from './area-color.directive';
import { BlocksComponent } from './blocks/blocks.component';
import { Lunes4Component } from './lunes4/lunes4.component';
import { Martes4Component } from './martes4/martes4.component';
import { Miercoles4Component } from './miercoles4/miercoles4.component';
import { Lunes5Component } from './lunes5/lunes5.component';
import { Martes5Component } from './martes5/martes5.component';
import { Miercoles5Component } from './miercoles5/miercoles5.component';
import { Jueves2Component } from './jueves2/jueves2.component';
import { Viernes2Component } from './viernes2/viernes2.component';
import { FooterComponent } from './footer/footer.component'
@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    HomeComponent,
    CalendarioTableComponent,
    AreasTableComponent,
    ListaTrabajosComponent,
    ModalTrabajoComponent,
    CalendarioPostersComponent,
    CalendarioPlenariasComponent,
    CalendarioPlenarias2Component,
    CalendarioOralesComponent,
    CalendarioDetalleComponent,
    TimeBlockComponent,
    TimeBlockDirective,
    Lunes1Component,
    Lunes2Component,
    Lunes3Component,
    Martes1Component,
    Martes2Component,
    Martes3Component,
    Miercoles1Component,
    Miercoles2Component,
    Miercoles3Component,
    Jueves1Component,
    Jueves3Component,
    Viernes1Component,
    Viernes3Component,
    Jueves4Component,
    TimePipe,
    RoomPipe,
    PresDatePipe,
    AreaPipe,
    AreaColorDirective,
    BlocksComponent,
    Lunes4Component,
    Martes4Component,
    Miercoles4Component,
    Lunes5Component,
    Martes5Component,
    Miercoles5Component,
    Jueves2Component,
    Viernes2Component,
    FooterComponent
  ],
  imports: [
    BrowserModule,
    MatProgressSpinnerModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    MatMenuModule,
    MatButtonModule,
    MatIconModule,
    MatToolbarModule,
    MatInputModule,
    MatSelectModule,
    MatDialogModule,
    FormsModule, 
    ReactiveFormsModule,
    HttpClientModule,
    MatStepperModule,
    MatExpansionModule
  ],
  providers: [ApiService],
  bootstrap: [AppComponent],
  entryComponents:[ModalTrabajoComponent]
  
})
export class AppModule { }


