import { Directive, Input, HostListener, ElementRef, Renderer2, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import * as moment from 'moment';
@Directive({
  selector: '[timeBlock]'
})
export class TimeBlockDirective implements OnInit {

  @Input('timeBlock') tbRowSpan: string;
  @Input('startTime') startTime: any;
  @Input('endTime') endTime: any;
  constructor(private el: ElementRef, private renderer: Renderer2, private router: Router) {

  }

  ngOnInit() {
    this.startTime=moment(this.startTime);
    this.endTime=moment(this.endTime);
    let span = this.getBlocks(this.startTime,this.endTime).toString();
    this.renderer.setAttribute(this.el.nativeElement, 'rowspan', span);
  }

  getBlocks(startTime:any,endTime:any){
    let auxDate:any=startTime;
    let blocks:number=0;
    while(moment(auxDate).isBefore(moment(endTime)) && blocks<20){
      auxDate=moment(auxDate).add(moment.duration('00:15:00'));
      blocks++;
    }
    return blocks;
  }

  @HostListener('click', ['$event'])
  onclick() {
    this.router.navigate([`../areas/oral/${this.startTime._i}/${this.endTime._i}`]);
  }



}
